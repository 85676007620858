import Api from "../../Api";


const END_POINT_ALLUNIDADES = 'user/materia-prima/materia-prima-units/all';
const END_POINT_CREATEUNIDADES = 'user/materia-prima/materia-prima-units/create';
const END_POINT_UPDATEUNIDADES = 'user/materia-prima/materia-prima-units/update';
const END_POINT_DELETEUNIDADES = 'user/materia-prima/materia-prima-units/delete';

export default {
    GetAllUnidades(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLUNIDADES, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateUnidades(data, auth) {
        return Api.post(END_POINT_CREATEUNIDADES, {
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateUnidades(data, auth) {
        return Api.patch(END_POINT_UPDATEUNIDADES, {
            'id': data.id,
            'name': data.name,
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByUnidades(id, auth) {
        return Api.delete(END_POINT_DELETEUNIDADES + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}