import Api from "../../Api";


const END_POINT_ALLSERVICES = 'user/services/all';
const END_POINT_CREATESERVICE = 'user/services/create';
const END_POINT_UPDATESERVICE = 'user/services/update';
const END_POINT_DELETESERVICE = 'user/services/delete';

export default {
    GetAllServices(data) {
        return Api.get(END_POINT_ALLSERVICES, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateService(data, auth) {
        return Api.post(END_POINT_CREATESERVICE, {
            'nombre': data.name,
            'costo_ferrebaztan': data.costo_ferrebaztan,
            'margen': data.margen,
            'pv': data.pv
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateService(data, auth) {
        return Api.patch(END_POINT_UPDATESERVICE, {
            'id': data.id,
            'nombre': data.name,
            'costo_ferrebaztan': data.costo_ferrebaztan,
            'margen': data.margen,
            'pv': data.pv
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByService(id, auth) {
        return Api.delete(END_POINT_DELETESERVICE + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}