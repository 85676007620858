import Api from "../../Api";


const END_POINT_ALLMATERIAPRIMAS = 'user/materia-prima/all';
const END_POINT_CREATEMATERIAPRIMA = 'user/materia-prima/create';
const END_POINT_UPDATEMATERIAPRIMA = 'user/materia-prima/update';
const END_POINT_DELETEMATERIAPRIMA = 'user/materia-prima/delete';

export default {
    GetAllMateriaPrima(data) {
        //return Api.get(END_POINT, data);
        return Api.get(END_POINT_ALLMATERIAPRIMAS, {
            headers: {
                'Authorization': 'Bearer ' + data.access_token
            }
        });
    },

    PostCreateMateriaPrima(data, auth) {
        return Api.post(END_POINT_CREATEMATERIAPRIMA, {
            'codigo': data.cod_macro,
            'descripcion': data.descripcion,
            'peso_teorico': data.peso_teorico,
            'maximo': data.maximo,
            'minimo': data.minimo,
            'ancho': data.ancho,
            'largo': data.largo,
            'peso_mm2': data.peso_mm2,
            'materia_prima_family_id': data.familia,
            'materia_prima_unit_id': data.unidad,
            'service_id': data.service_id,
            'calibre':data.calibre
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    UpdateMateriaPrima(data, auth) {
        return Api.patch(END_POINT_UPDATEMATERIAPRIMA, {
            'id': data.id,
            'codigo': data.cod_macro,
            'descripcion': data.descripcion,
            'peso_teorico': data.peso_teorico,
            'maximo': data.maximo,
            'minimo': data.minimo,
            'ancho': data.ancho,
            'largo': data.largo,
            'peso_mm2': data.peso_mm2,
            'materia_prima_family_id': data.familia,
            'materia_prima_unit_id': data.unidad,
            'service_id': data.service_id,
            'calibre':data.calibre
        }, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            }
        });
    },


    DeleteByMateriaPrima(id, auth) {
        return Api.delete(END_POINT_DELETEMATERIAPRIMA + '/' + id, {
            headers: {
                'Authorization': 'Bearer ' + auth.access_token
            },
        });
    }


}