<template>
  <div>
    <!-- Hero -->
    <base-page-heading
      title="Materia Prima"
      subtitle="| Administrar de Materia Prima"
      class="heading-block"
    >
      <template #extra>
        <div class="mt-5 mt-sm-0 ml-sm-3">
          <b-button variant="alt-primary" class="" href="javascript:void(0)" v-click-ripple v-b-popover.hover.bottom="'Alta de un Materia Prima'" @click="ShowModalMateriaP()" style="width: 200px">
            <i class="fa fa-plus-square mr-1"></i> Nueva Materia Prima
          </b-button>
          <!-- Small Block Modal -->
          <b-modal id="modal-materiap" size="lg" body-class="p-0" hide-footer hide-header>
            <div class="block block-rounded block-themed block-transparent mb-0">
              <div class="block-header bg-primary-dark">
                <h3 class="block-title">
                  <i class="fa fa-user mr-1"></i> Alta de Materia Prima
                </h3>
                <div class="block-options">
                  <button type="button" class="btn-block-option" @click="ShowModalMateriaP()"></button>
                  <i style="cursor:pointer;" class="fa fa-fw fa-times" @click="$bvModal.hide('modal-materiap')"></i>
                </div>
              </div>
              <div class="block-content font-size-sm">
                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          # Código <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.cod_macro"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group label-for="comentario">
                        <template #label>
                          Calibre
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.calibre"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group label-for="comentario">
                        <template #label>
                          Descripción
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.descripcion"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Familia <span class="text-danger">*</span>
                        </template>
                        <select  v-model="form.familia" class="form-control">
                          <option :value="data.id" v-for="data in familias" :key="data.id">{{ data.name }}</option>
                        </select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Unidad <span class="text-danger">*</span>
                        </template>
                        <select  v-model="form.unidad" class="form-control">
                          <option :value="data.id" v-for="data in unidades" :key="data.id">{{ data.name }}</option>
                        </select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Servicios <span class="text-danger">*</span>
                        </template>
                        <select  v-model="form.service_id" class="form-control">
                            <option :value="data.id" v-for="data in servicios" :key="data.id">{{ data.nombre }}</option>
                        </select>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Peso Teórico <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.peso_teorico"></b-form-input>
                      </b-form-group>
                    </b-col>
                        
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Ancho <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.ancho"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Largo <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.largo" @change="ChangeOnPesoMM2()"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="ml-4 mt-3">
                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                          <template #label>
                            Peso MM2 <span class="text-danger">*</span>
                          </template>
                          <b-form-input placeholder="-" size="sm" disabled v-model="form.peso_mm2"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Máximo <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.maximo"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col sm="4">
                      <b-form-group label-for="no_cliente">
                        <template #label>
                          Mínimo <span class="text-danger">*</span>
                        </template>
                        <b-form-input id="no_cliente" name="no_cliente" placeholder="-" size="sm" v-model="form.minimo"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <br />
                </form>
              </div>

              <div class="block-content block-content-full text-right border-top">
                <b-button variant="alt-primary" class="mr-1" @click="$bvModal.hide('modal-materiap')">Cerrar</b-button>
                <template v-if="edit == false">
                  <b-button variant="primary" @click="CreateMateriaP()">Guardar</b-button>
                </template>
                <template v-else> 
                  <b-button variant="danger" @click="UpdateMateriaP()">Actualizar</b-button>
                </template>
              </div>
            </div>
          </b-modal>
          <!-- END Small Block Modal -->
        </div>
      </template>
    </base-page-heading>

    <div>
      <base-block rounded title="">
        <b-row>
          <b-col sm="6" xl="2">
            <b-dropdown
              id="dropdown-default-light"
              variant="light"
              text="Acciones agrupadas"
            >
              <b-dropdown-item class="font-size-sm"
                >Habilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm"
                >Deshabilitar Seleccion</b-dropdown-item
              >
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item class="font-size-sm"
                >Eliminar Seleccion</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" class="ml-3">
            <!-- Form Inline - Alternative Style -->
            <b-form inline>
              <b-form-input
                type="search"
                class="form-control-alt mr-sm-2 mb-sm-0"
                id="example-if-email2"
                name="example-if-email2"
                placeholder="Búsqueda"
                v-model="filter"
              ></b-form-input>
            </b-form>
            <!-- END Form Inline - Alternative Style -->
          </b-col>

          <b-col>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="float-right"
              right
            >
              <template #button-content>
                <i class="si si-settings"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="/clients.csv" download
                >Exportar Clientes a Excel</b-dropdown-item
              >
            </b-dropdown>
            <b-dropdown
              variant="light"
              toggle-class="text-decoration-none"
              no-caret
              class="mr-1 float-right"
              right
            >
              <template #button-content>
                <i class="fas fa-fw fa-filter"></i>
              </template>
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Nombre</b-dropdown-item
              >
              <b-dropdown-item class="font-size-sm" href="#"
                >Por Departamento</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <br />
        <b-overlay :show="showoverlay" rounded="sm">
            <div class="table-responsive">
              <b-table style="cursor: pointer;" :items="materiap" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" responsive striped hover >
                  <template #cell(actions)="row" >
                      <b-button size="sm" @click="EditMateriaP(row.item)" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-pencil-alt"></i>
                      </b-button>
                      <b-button size="sm" @click="DeleteMateriaP(row.item.id)"  class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled">
                          <i class="fa fa-fw fa-times"></i>
                      </b-button>
                  </template>
              </b-table>
            </div>
        </b-overlay>
        <b-row>
            <b-col sm="7" md="2" class="my-1">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
        </b-row>
      </base-block>
    </div>
  </div>
</template>

<script>
import MateriaP from "../../../api/admin/materia_prima/Materia_Prima";
import Familia from "../../../api/admin/materia_prima/Familia";
import Unidades from "../../../api/admin/materia_prima/Unidades";
import Servicios from "../../../api/admin/services/Services";
import { validationMixin } from "vuelidate";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      materiap: [],
      familias:[],
      unidades:[],
      servicios:[],
      filter: null,
      timeout: null,
      edit : false,
      showoverlay:false,
      fields: [
        {key: 'codigo',sortable: true, label: 'CÓDIGO MACRO'},
        {key: 'descripcion',sortable: true, label: 'DESCRIPCIÓN'},
        {key: 'materia_prima_family', formatter: value => {
              return value.name
          },sortable: true, label: 'FAMILIA'},
        {key: 'materia_prima_unit', formatter: value => {
              return value.name
          },sortable: true, label: 'UNIDAD'},
          {key: 'service_type', formatter: value => {
              return value.nombre.length ? value.nombre : 'No especificado'
          },sortable: true, label: 'SERVICIO'},
        {key: 'peso_teorico',sortable: true, label: 'PESO TEORICO'},
        {key: 'maximo',sortable: true, label: 'MÁXIMO'},
        {key: 'minimo',sortable: true, label: 'MÍNIMO'},
        {key: 'actions',sortable: true, label: 'ACCIONES'},
      ],
      form: {
        id:"",
        cod_macro:"",
        descripcion:"",
        peso_teorico:"",
        maximo:"",
        minimo:"",
        ancho:"",
        largo:"",
        peso_mm2:"",
        familia:"",
        unidad:"",
        service_id:"",
        calibre:""
      },
      errors:[],
    };
  },
  computed: {
    totalRows() {
      return this.materiap.length
    }
  },


  methods: {

    ChangeOnPesoMM2(){
      this.form.peso_mm2 = parseFloat(this.form.peso_teorico / (this.form.largo * this.form.ancho)).toFixed(12)
      console.log(this.form.peso_mm2);
    },

    AllMateriaPrima() {
      this.showoverlay = true;
      let auth = JSON.parse(localStorage.autentication);
      MateriaP.GetAllMateriaPrima(auth)
        .then((response) => {
          this.materiap = response.data;
          this.showoverlay = false;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllFamilias() {
      let auth = JSON.parse(localStorage.autentication);
      Familia.GetAllFamilias(auth)
        .then((response) => {
          this.familias = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllUnidades() {
      let auth = JSON.parse(localStorage.autentication);
      Unidades.GetAllUnidades(auth)
        .then((response) => {
          this.unidades = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    AllServicios() {
      let auth = JSON.parse(localStorage.autentication);
      Servicios.GetAllServices(auth)
        .then((response) => {
          this.servicios = response.data;
        })
        .catch((error) => {
          console.log(error.data);
        });
    },

    CreateMateriaP() {
      this.$bvModal.hide("modal-materiap");
      if (this.form.familia && this.form.descripcion ) {
          this.showoverlay = true;
          let auth = JSON.parse(localStorage.autentication);
          MateriaP.PostCreateMateriaPrima(this.form, auth)
            .then((response) => {
              this.AllMateriaPrima();
              this.showoverlay = false;
              Swal.fire({
                title: "Genial!",
                text: response.data.message,
                icon: "success",
              });
              this.form = {
                  id:"",
                  cod_macro:"",
                  descripcion:"",
                  peso_teorico:"",
                  maximo:"",
                  minimo:"",
                  ancho:"",
                  largo:"",
                  peso_mm2:"",
                  familia:"",
                  unidad:"",
                  service_id:"",
                  calibre:""
              };
              
            })
            .catch((error) => {
              const obj = error.response.data.errors;
              Object.entries(obj).forEach( elements => 
                  Swal.fire({
                    title: "Que mal!",
                    text: elements[1][0],
                    icon: "error",
                  })
              );
            });
      }else{
         this.errors = []
          if (!this.form.familia) {
              let me=this
              me.errors.push('Seleccione una familia')
          }   
          if (!this.form.descripcion) {
              let me=this
              me.errors.push('Descripcion del producto requerido')
          }          
          Swal.fire({
            title: "Corrija los siguientes errores",
            icon: "error",
            html: this.errors
                .map(item => `<p> <li>${item}</li></p>`)
                .join('')
          });
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-light", "text-dark"];
      } else {
        return ["bg-light", "text-dark"];
      }
    },

    ShowModalMateriaP() {
      this.form = {
        id:"",
        cod_macro:"",
        descripcion:"",
        peso_teorico:"",
        maximo:"",
        minimo:"",
        ancho:"",
        largo:"",
        peso_mm2:"",
        familia:"",
        unidad:"",
        service_id:"",
        calibre:""
      };
      this.$bvModal.show("modal-materiap");
      this.edit = false;
    },

    EditMateriaP(data) {
      this.$bvModal.show("modal-materiap");
      this.edit = true;
      this.form = {
        id: data.id,
        cod_macro: data.codigo,
        descripcion: data.descripcion,
        peso_teorico: data.peso_teorico,
        maximo: data.maximo,
        minimo: data.minimo,
        ancho: data.ancho,
        largo: data.largo,
        peso_mm2: data.peso_mm2,
        familia: data.materia_prima_family_id,
        unidad: data.materia_prima_unit_id,
        service_id: data.service_id,
        calibre: data.calibre
      };
    },

    UpdateMateriaP(){
        this.$bvModal.hide("modal-materiap");
        this.showoverlay = true;
        let auth = JSON.parse(localStorage.autentication);
        MateriaP.UpdateMateriaPrima(this.form, auth)
        .then((response) => {
          this.AllMateriaPrima();
          this.showoverlay = false;
          Swal.fire({
            title: "Genial!",
            text: response.data.message,
            icon: "success",
          });
            this.form = {
                id:"",
                cod_macro: "",
                descripcion:"",
                familia:"",
                unidad:"",
                categoria:"",
                peso_teorico:"",
                maximo:"",
                minimo:"",
                calibre:""
            };     
        })
        .catch((error) => {
          Swal.fire({
            title: "Que mal!",
            text: error.message,
            icon: "error",
          });
          this.AllMateriaPrima();
        });
    },

    DeleteMateriaP(id){
        Swal.fire({
            title: "¿Esta seguro de eliminar el registro?",
            text: "¿Eliminar?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText: "Cancelar",
        })
        .then(resultado => {
            if (resultado.value) {
                 this.showoverlay = true;
                let auth = JSON.parse(localStorage.autentication);
                MateriaP.DeleteByMateriaPrima(id, auth)
                .then((response) => {
                  this.AllMateriaPrima();
                  this.showoverlay = false;
                  Swal.fire({
                    title: "Genial!",
                    text: response.data.message,
                    icon: "success",
                  });
                  
                })
                .catch((error) => {
                  Swal.fire({
                    title: "Que mal!",
                    text: error.message,
                    icon: "error",
                  });
                  this.AllMateriaPrima();
                });
            } else {
                // Dijeron que no
                console.log("*NO se elimina la venta*");
            }
        });

        
    },
   

  },

  mounted() {
    let me = this;    
    me.AllMateriaPrima();
    me.AllFamilias();
    me.AllUnidades();
    me.AllServicios();
  },
};
</script>

<style scoped>
.heading-block {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 60px;
  z-index: 1;
}
.scrollable {
  overflow-y: auto;
  max-height: 280px;
}
</style>
